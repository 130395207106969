<template>
  <v-row class="px-10" dense>
    <v-col cols="12" md="6">
      <v-radio-group
        :disabled="true"
        class="ma-0 pt-2"
        v-model="client.person_type"
        :column="false"
        mandatory
        row
        dense
      >
        <div
          class="d-flex flex-md-row flex-sm-column pa-2 justify-space-around"
          style="width: 100%"
        >
          <v-card class="px-4 py-2 my-2">
            <v-radio label="Pessoa física" :value="'PF'" />
          </v-card>

          <v-card class="px-2 py-2 my-2">
            <v-radio class="ml-5" label="Pessoa jurídica" :value="'PJ'" />
          </v-card>
        </div>
      </v-radio-group>
    </v-col>

    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="client.business_id"
        label="Ramo de Atuação*"
        :items="lineOfBusiness"
        item-text="name"
        item-value="id"
        dense
        rounded
        outlined
      />
    </v-col>

    <v-col cols="12" md="6">
      <v-autocomplete
        :disabled="true"
        v-model="client.seller_id"
        label="Vendedor*"
        :items="sellers"
        :rules="[() => !!client.seller_id || 'Este campo é obrigatório']"
        :item-text="'title'"
        item-value="id"
        dense
        rounded
        outlined
      />
    </v-col>
    
    <v-col cols="12" md="6">
      <v-text-field
        v-model="client.website"
        label="Site"
        placeholder="https://exemplo.com.br"
        dense
        rounded
        outlined
      >
      </v-text-field>
    </v-col>
    <br />
    <keep-alive max="2">
      <component
        @deactivated="reset"
        :client="client"
        :is="client.person_type"
      />
    </keep-alive>
  </v-row>
</template>

<script>
export default {
  props: {
    client: Object,
    lineOfBusiness: Array,
    agencies: Array,
    sellers: Array,
    moods: Array,
  },
  watch: {
    "client.name": function () {
      if (this.client.person_type == "PF") {
        this.client.client_contact[0].name = this.client.name;
      }
    },
  },
  components: {
    PF: () => import("./PF"),
    PJ: () => import("./PJ"),
  },
  data: () => ({
    reset: "deactivated",
  }),
  methods: {
    getSellerID(item) {
      console.log(item);
      let idx = this.agencies.indexOf(item);
      if (this.agencies[idx].seller_id != null)
        this.client.seller_id = this.agencies[idx].seller_id;
    },
  },
};
</script>